import React from 'react'
import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Content from '../components/ui/content'
import PageHeader from '../components/ui/page-header'
import Cta from '../components/ui/cta'
import { StaticImage } from 'gatsby-plugin-image'

export default function Products() {
  return (
    <Layout>
      <Seo title='Ürünler' description='test açıklama' article lang='tr' />
      <PageHeader title='Ürünler' />
      <Container className='grid grid-cols-2 gap-4 my-20'>
        <div className='col-span-2 md:col-span-1 border flex flex-wrap items-center justify-center h-36'>
          <span className='text-5xl'>Ham Kumaşlar</span>
        </div>
        <div className='col-span-2 md:col-span-1 border flex flex-wrap items-center justify-center h-36'>
          <span className='text-5xl'>Renkli Kumaşlar</span>
        </div>
        <div className='col-span-2 md:col-span-1 border flex flex-wrap items-center justify-center h-36'>
          <span className='text-5xl'>Baskılı Kumaşlar</span>
        </div>
        <div className='col-span-2 md:col-span-1 border flex flex-wrap items-center justify-center h-36'>
          <span className='text-5xl'>Baskılı Pikeler</span>
        </div>
        <div className='col-span-2  border flex flex-wrap items-center justify-center h-36'>
          <span className='text-5xl'>Renkli Pikeler</span>
        </div>
      </Container>
    </Layout>
  )
}
